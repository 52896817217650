// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ol-control.layer-switcher {
  top: 0.5em;
  right: 0.5em;
  text-align: right;
}
.ol-control.layer-switcher.shown.layer-switcher-activation-mode-click {
  padding-right: 0.5em;
}
.ol-control.layer-switcher.shown.layer-switcher-activation-mode-click > button {
  right: 0;
  border-left: 0;
}
.ol-control.layer-switcher.shown.layer-switcher-activation-mode-click > .panel {
  display: block;
}
.ol-control.layer-switcher.layer-switcher-activation-mode-click > .panel {
  display: none;
}
.ol-control.layer-switcher button {
  right: 0;
  border-left: 0;
}

.ol-control.layer-switcher li.layer {
  list-style: none;
}


.data-layer-add { 
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
}

.data-layer-remove { 
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
  margin-top: 10px;
}`, "",{"version":3,"sources":["webpack://./GeomapPanel.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,YAAY;EACZ,iBAAiB;AACnB;AACA;EACE,oBAAoB;AACtB;AACA;EACE,QAAQ;EACR,cAAc;AAChB;AACA;EACE,cAAc;AAChB;AACA;EACE,aAAa;AACf;AACA;EACE,QAAQ;EACR,cAAc;AAChB;;AAEA;EACE,gBAAgB;AAClB;;;AAGA;IACI,aAAa;IACb,yBAAyB;IACzB,mBAAmB;AACvB;;AAEA;EACE,aAAa;EACb,yBAAyB;EACzB,mBAAmB;EACnB,gBAAgB;AAClB","sourcesContent":[".ol-control.layer-switcher {\n  top: 0.5em;\n  right: 0.5em;\n  text-align: right;\n}\n.ol-control.layer-switcher.shown.layer-switcher-activation-mode-click {\n  padding-right: 0.5em;\n}\n.ol-control.layer-switcher.shown.layer-switcher-activation-mode-click > button {\n  right: 0;\n  border-left: 0;\n}\n.ol-control.layer-switcher.shown.layer-switcher-activation-mode-click > .panel {\n  display: block;\n}\n.ol-control.layer-switcher.layer-switcher-activation-mode-click > .panel {\n  display: none;\n}\n.ol-control.layer-switcher button {\n  right: 0;\n  border-left: 0;\n}\n\n.ol-control.layer-switcher li.layer {\n  list-style: none;\n}\n\n\n.data-layer-add { \n    display: flex;\n    justify-content: flex-end;\n    margin-bottom: 10px;\n}\n\n.data-layer-remove { \n  display: flex;\n  justify-content: flex-end;\n  margin-bottom: 10px;\n  margin-top: 10px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
